/* eslint-disable no-nested-ternary */
import {
  Button, Col, Modal, Row,
} from 'antd';
import { cloneDeep, map } from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  Login,
  PasswordRenew,
  PasswordRenewSuccess,
  PasswordReset,
  Signup1,
  Signup2,
  Signup3,
  Signup4,
} from '../../components/AuthSteps/AuthSteps';
import StoreConnector from '../../hoc/StoreConnector';
import withParams from '../../hoc/withParams';
import { openLogin } from '../../redux/slices/modals';
import {
  activateWithCodeThunk,
  decryptInvoiceHashThunk,
  resetPasswordThunk,
  sendResetThunk,
  signInThunk,
  signUpThunk,
} from '../../redux/thunks/auth';
import {
  closeThunk,
  openPasswordRenewThunk,
  openPasswordResetThunk,
  openSignup1Thunk,
  openSignup2Thunk,
  saveContactInStateThunk,
} from '../../redux/thunks/modals';
import stateUtils from '../../utils/state';
import './styles.css';

class AuthModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'login',
      open: false,
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      street: '',
      building: '',
      zipcode: '',
      city: '',
      recaptcha: '',
      navigateDashboard: false,
      isNavigating: false,
      validators: [
        { text: 'Mindestens 8 Zeichen', isValid: false, regex: /^.{8,}$/ },
        { text: 'Mindestens eine Zahl', isValid: false, regex: /.*\d/ },
        { text: 'Mindestens ein Kleinbuchstabe', isValid: false, regex: /.*[a-z]/ },
        { text: 'Mindestens ein Grossbuchstabe', isValid: false, regex: /.*[A-Z]/ },
        {
          text: 'Mindestens ein Sonderzeichen',
          isValid: false,
          regex: /(?=.*?[#?!@$%^&*\-/+"*ç%&/()=?`è!£àé_:;><≤°§§±“#Ç[\]|{}≠'¿^´¨‘$¶–.…,«<≤])/,
        },
        { text: 'Passwort-Bestätigung stimmt überein', isValid: false },
      ],
    };
  }

  componentDidMount() {
    if (this.state.step !== this.props.step) {
      this.setState({ step: this.props.step });
    }

    if (this.state.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }

    if (this.props.params.passwordResetHash) {
      this.props.dispatch(openPasswordRenewThunk());
    }

    if (
      this.props.params.invoiceHash
      && !this.props.user.user_id
      && !this.props.savedContact
    ) {
      this.props.dispatch(
        decryptInvoiceHashThunk(this.props.params.invoiceHash),
      );
    }
    if (this.props.savedContact) {
      const { savedContact, user } = this.props;
      this.setState({
        email: savedContact.email,
        phone: savedContact.phone,
        firstName: savedContact.firstName,
        lastName: savedContact.lastName,
        street: savedContact.street,
        building: savedContact.building,
        zipcode: savedContact.zipcode,
        city: savedContact.city,
        recaptcha: '',
      });

      if (savedContact.active && !user.user_id) {
        this.props.dispatch(openLogin());
      }
    }
  }

  componentDidUpdate() {
    if (this.state.step !== this.props.step) {
      this.setState({ step: this.props.step });
    }

    if (this.state.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }

    return false;
  }

  setSignupFields(fields) {
    this.setState((prevState) => ({ ...prevState, ...fields }));
  }

  // TODO refactor
  updateValidators(value, password, confirmation) {
    const validatorsToUpdate = cloneDeep(this.state.validators);
    const updatedValidators = map(validatorsToUpdate, (validator) => {
      const validatorCopy = validator;
      if (validatorCopy.regex && !confirmation) {
        validatorCopy.isValid = value.match(validator.regex);
      } else if (!validatorCopy.regex && confirmation) {
        validatorCopy.isValid = password === value;
      }
      return validatorCopy;
    });

    this.setState({ validators: updatedValidators });
  }

  validatePasswordRenew(passwordSignup, passwordConfirmation) {
    const hashToken = this.props.params.passwordResetHash;

    if (passwordSignup === passwordConfirmation) {
      this.props.dispatch(
        resetPasswordThunk({
          password: passwordSignup,
          hashToken,
        }),
      );
    }
  }

  render() {
    const {
      step,
      open,
      email,
      phone,
      firstName,
      lastName,
      street,
      building,
      zipcode,
      city,
      recaptcha,
    } = this.state;

    const stepTitle = step === 'login'
      ? 'Login'
      : step === 'signup1'
        ? 'Registrierung'
        : step === 'signup2'
          ? 'Passwort bestätigen'
          : step === 'signup3'
            ? 'Sicherheitscode'
            : step === 'signup4'
              ? 'Abschluss'
              : '';
    if (this.props.savedContact.contact_id && !this.state.email) {
      this.setState({
        email: this.props.savedContact.email,
        phone: this.props.savedContact.phone,
        firstName: this.props.savedContact.firstName,
        lastName: this.props.savedContact.lastName,
        street: this.props.savedContact.Address.street,
        building: this.props.savedContact.Address.building,
        zipcode: this.props.savedContact.Address.zipcode,
        city: this.props.savedContact.Address.city,
      });
    }
    if (
      this.props.step === 'signup4'
      && this.props.savedContact
      && !this.state.isNavigating
    ) {
      this.setState({ navigateDashboard: true, isNavigating: true });
      this.props.dispatch(saveContactInStateThunk(''));
    }
    if (this.props.step === 'login' && this.props.user.user_id) {
      this.props.dispatch(closeThunk());
    }

    return (
      <Modal
        visible={open}
        onCancel={() => this.props.dispatch(closeThunk())}
        footer={null}
        title={stepTitle}
        style={{
          borderRadius: '10px',
        }}
      >
        {this.state.navigateDashboard && (
          <Navigate to="/dashboard/invoices" replace />
        )}
        {step === 'login' && (
          <Login
            openPasswordReset={() => this.props.dispatch(openPasswordResetThunk())}
            signIn={(e, p) => this.props.dispatch(signInThunk(e, p))}
            openSignup1={() => this.props.dispatch(openSignup1Thunk())}
            bookingWidget={false}
          />
        )}
        {step === 'signup1' && (
          <Signup1
            signupFields={{
              email,
              phone,
              firstName,
              lastName,
              street,
              building,
              zipcode,
              city,
              recaptcha,
            }}
            setSignupFields={(fields) => this.setSignupFields(fields)}
            openSignup2={() => this.props.dispatch(openSignup2Thunk())}
            openLogin={() => this.props.dispatch(openLogin())}
            fromInvoice={false}
            bookingWidget={false}
          />
        )}
        {step === 'signup2' && (
          <Signup2
            validators={this.state.validators}
            updateValidators={(value, password, confirmation) => this.updateValidators(value, password, confirmation)}
            signupFields={{
              email,
              phone,
              firstName,
              lastName,
              street,
              building,
              zipcode,
              city,
              recaptcha,
            }}
            signUp={(fields) => this.props.dispatch(signUpThunk(fields))}
          />
        )}
        {step === 'signup3' && (
          <Signup3
            activateWithCode={(verificationCode) => this.props.dispatch(
              activateWithCodeThunk({
                userId: this.props.userId,
                code: verificationCode,
              }),
            )}
          />
        )}
        {step === 'signup4' && (
          <Signup4 close={() => this.props.dispatch(closeThunk())} />
        )}
        {step === 'passwordReset' && (
          <PasswordReset
            sendReset={(resetEmail, cApp) => this.props.dispatch(sendResetThunk({ email: resetEmail, cApp }))}
            openLogin={() => this.props.dispatch(openLogin())}
            openSignup1={() => this.props.dispatch(openSignup1Thunk())}
          />
        )}
        {step === 'passwordRenew' && (
          <PasswordRenew
            validators={this.state.validators}
            updateValidators={(value, password, confirmation) => this.updateValidators(value, password, confirmation)}
            validatePasswordRenew={(passwordSignup, passwordConfirmation) => this.validatePasswordRenew(passwordSignup, passwordConfirmation)}
          />
        )}
        {step === 'passwordRenewSuccess' && (
          <PasswordRenewSuccess
            openLogin={() => this.props.dispatch(openLogin())}
          />
        )}
        {/**
         * signUpUnfinished is only a placeholder
         */}
        {step === 'signUpUnfinished' && (
          <Row>
            <Col span={24} style={{ marginBottom: '20px' }}>
              Registierung erfolgreich!
            </Col>
            <Col span={24} style={{ marginBottom: '20px' }}>
              <Button
                primary
                className="authLoginButton"
                onClick={() => this.props.dispatch(closeThunk())}
              >
                Jetzt loslegen
              </Button>
            </Col>
          </Row>
        )}
        {step === 'signupInvoice' && (
          <Signup1
            signupFields={{
              email,
              phone,
              firstName,
              lastName,
              street,
              building,
              zipcode,
              city,
              recaptcha,
            }}
            setSignupFields={(fields) => this.setSignupFields(fields)}
            openSignup2={() => this.props.dispatch(openSignup2Thunk())}
            openLogin={() => this.props.dispatch(openLogin())}
            fromInvoice
          />
        )}
      </Modal>
    );
  }
}

export default StoreConnector(
  withParams(AuthModals),
  stateUtils.fullStateMap,
  {},
);

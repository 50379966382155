import {
  Button, Col, DatePicker, Input, Row,
} from 'antd';
import dayjs from 'dayjs';
import React, { PureComponent } from 'react';

import StoreConnector from '../../hoc/StoreConnector';
import {
  getUserDataThunk,
  updateUserdataThunk,
} from '../../redux/thunks/dashboard';
import stateUtils from '../../utils/state';
import './style.css';

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      street: '',
      building: '',
      zipcode: '',
      city: '',
      state: '',
      country: '',
      birthDate: '',
      phone: '',
      email: '',
      missingFieldsError: false,
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  onFirstNameChange(value) {
    this.setState({ firstName: value });
  }

  onLastNameChange(value) {
    this.setState({ lastName: value });
  }

  onStreetChange(value) {
    this.setState({ street: value });
  }

  onBuildingChange(value) {
    this.setState({ building: value });
  }

  onZipcodeChange(value) {
    this.setState({ zipcode: value });
  }

  onCityChange(value) {
    this.setState({ city: value });
  }

  onStateChange(value) {
    this.setState({ state: value });
  }

  onCountryChange(value) {
    this.setState({ country: value });
  }

  onBirthDateChange(value) {
    this.setState({ birthDate: value });
  }

  onPhoneChange(value) {
    this.setState({ phone: value });
  }

  onEmailChange(value) {
    this.setState({ email: value });
  }

  saveUserData() {
    const {
      firstName,
      lastName,
      street,
      building,
      zipcode,
      city,
      state,
      country,
      birthDate,
      phone,
      email,
    } = this.state;

    if (
      email
      && email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
      && firstName
      && lastName
    ) {
      const userUpdateData = {
        user: {
          firstName,
          lastName,
          phone,
          email,
        },
        address: {
          street,
          building,
          zipcode,
          city,
          state,
          country,
        },
      };

      // eslint-disable-next-line no-unused-expressions
      birthDate ? userUpdateData.user.dateOfBirth = birthDate : '';

      this.props.dispatch(
        updateUserdataThunk(this.props.user.user_id, userUpdateData),
      );
    } else {
      this.setState({ missingFieldsError: true });
    }
  }

  reflectProps(props) {
    if (
      props.user
      && props.user.user_id
      && !props.isFetchingUserData
      && !props.isValidUserData
      && !props.isFailedFetchingUserData
    ) {
      props.dispatch(getUserDataThunk(props.user.user_id));
    }

    if (props.isValidUserData) {
      this.setState({
        firstName: props.userData.firstName,
        lastName: props.userData.lastName,
        street: props.userData.Address.street,
        building: props.userData.Address.building,
        zipcode: props.userData.Address.zipcode,
        city: props.userData.Address.city,
        state: props.userData.Address.state,
        country: props.userData.Address.country,
        birthDate: props.userData.dateOfBirth ? dayjs(props.userData.dateOfBirth) : '',
        phone: props.userData.phone,
        email: props.userData.email,
      });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      street,
      building,
      zipcode,
      city,
      state,
      country,
      birthDate,
      email,
      phone,
      missingFieldsError,
    } = this.state;

    return (
      <div className="settings">
        <Row>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Vorname</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="Anna"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onFirstNameChange(e.target.value)}
                        value={firstName}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Nachname</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="Meier"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onLastNameChange(e.target.value)}
                        value={lastName}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Strasse</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="Freiestrasse"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onStreetChange(e.target.value)}
                        value={street}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Nummer</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="62"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onBuildingChange(e.target.value)}
                        value={building}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">PLZ</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="8032"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onZipcodeChange(e.target.value)}
                        value={zipcode}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Ort</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="Zürich"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onCityChange(e.target.value)}
                        value={city}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Kanton</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="ZH"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onStateChange(e.target.value)}
                        value={state}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Land</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="CH"
                        allowClear
                        bordered={false}
                        onChange={(e) => this.onCountryChange(e.target.value)}
                        value={country}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Geburtsdatum</p>
                    </Col>
                    <Col span={24}>
                      <DatePicker
                        placeholder="01.01.1964"
                        allowClear
                        bordered={false}
                        onChange={(date) => this.onBirthDateChange(date)}
                        value={birthDate}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row gutter={10}>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Email</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="annameier@gmail.com"
                        allowClear
                        disabled
                        bordered={false}
                        onChange={(e) => this.onEmailChange(e.target.value)}
                        value={email}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="authFieldBox">
                  <Row>
                    <Col span={24}>
                      <p className="authFieldLabel">Mobilnummer</p>
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder="41 79 298 72 00"
                        allowClear
                        disabled
                        bordered={false}
                        onChange={(e) => this.onPhoneChange(e.target.value)}
                        value={phone}
                        style={{
                          width: '100%',
                          border: 'none',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          color: '#3A3335',
                          fontSize: '14px',
                          letterSpacing: '-0.1px',
                          lineHeight: '21px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          {missingFieldsError && (
            <Col span={24} style={{ marginBottom: '10px' }}>
              <Row gutter={10}>
                <Col span={24}>
                  <p className="errorMessage">
                    Bitte füllen Sie alle Felder aus.
                  </p>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24} style={{ marginBottom: '20px' }}>
            <Button
              primary
              className="authLoginButton"
              onClick={() => this.saveUserData()}
            >
              Speichern
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(Settings, stateUtils.fullStateMap, {});

export default {
  server: {
    appName: 'Directory App',
    stage: 'development',
    port: '3300',
    host: 'localhost',
  },
  config: {
    brandName: 'Sanamia',
    defaultLanguage: 'de',
    defaultLanguageFull: 'de-DE',
    isDirectoryEnabled: '1',
    isBookingEnabled: '1',
    isLoginEnabled: '1',
  },
  images: {
    logoWhite: '',
    logoBlue: '',
    favicon: '',
  },
  links: {
    faqURL: '',
    termsURL: '',
    privacyURL: '',
    bookingTermsURL: '',
  },
  api: {
    commonService: 'https://pms-dev-api.pracman.ch',
    directoryEngine: 'https://api-dir-dev.pracman.ch',
    bookingEngine: 'https://booking-api-dev.pracman.ch',
    providerApps: 'https://itw-dev.pracman.ch',
    consumerApps: 'https://dev.sanamia.ch',
    bucketBaseUrl: 'https://sos-ch-dk-2.exo.io/itherapeut-web-public/booking_external',
  },
  keys: {
    hCaptchaKey: '436c9e5c-60ba-4612-bc28-b13c33cc8a7c',
  },
};

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StoreConnector from '../hoc/StoreConnector';
import LandingMain from '../containers/LandingMain/LandingMain';
import SearchHeader from '../containers/SearchHeader/SearchHeader';
import Page from '../hoc/Page';
import stateUtils from '../utils/state';
import withParams from '../hoc/withParams';
import { openLogin, openSignup1 } from '../redux/slices/modals';

function LandingPage(props) {
  const [location] = useState(useLocation());

  useEffect(() => {
    if (location.pathname.includes('signIn')) {
      props.dispatch(openLogin());
    } else if (location.pathname.includes('signUp')) {
      props.dispatch(openSignup1());
    } else if (location.pathname.includes('login')) {
      props.dispatch(openLogin());
    } else if (location.pathname.includes('registrieren')) {
      props.dispatch(openSignup1());
    }
  }, [location]);

  return (
    <>
      <SearchHeader />
      <LandingMain />
    </>
  );
}

export default Page(StoreConnector(
  withParams(LandingPage),
  stateUtils.fullStateMap,
  {},
));

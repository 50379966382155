import {
  Button, Checkbox, Col, Input, Row,
} from 'antd';
import { cloneDeep, map, some } from 'lodash';
import React, { useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import PasswordFields from '../PasswordFields/PasswordFields';
import env from '../../config/env';

/**
 * TODO
 * - check if i can separate validator into 1 function here insted leaving in authModals and Bookingwidget
 */

// eslint-disable-next-line no-unused-vars
function validatorsCheck(password, passwordSignup) {
  const [validators, setValidators] = useState([
    { text: 'Mindestens 8 Zeichen', isValid: false, regex: /^.{8,}$/ },
    { text: 'Mindestens eine Zahl', isValid: false, regex: /.*\d/ },
    { text: 'Mindestens ein Kleinbuchstabe', isValid: false, regex: /.*[a-z]/ },
    { text: 'Mindestens ein Grossbuchstabe', isValid: false, regex: /.*[A-Z]/ },
    {
      text: 'Mindestens ein Sonderzeichen',
      isValid: false,
      regex: /(?=.*?[#?!@$%^&*\-/+"*ç%&/()=?`è!£àé_:;><≤°§§±“#Ç[\]|{}≠'¿^´¨‘$¶–.…,«<≤])/,
    },
    { text: 'Passwort-Bestätigung stimmt überein', isValid: false },
  ]);
  const updateValidators = (value) => {
    const validatorsToUpdate = cloneDeep(validators);
    const updatedValidators = map(validatorsToUpdate, (validator) => {
      const validatorCopy = validator;
      if (validatorCopy.regex) {
        validatorCopy.isValid = value.match(validator.regex);
      } else {
        validatorCopy.isValid = passwordSignup === value;
      }
      return validatorCopy;
    });

    setValidators(updatedValidators);
  };

  updateValidators(password);
  console.log('validators-validatorsCheck', validators);
}

export function Login({
  openPasswordReset,
  signIn,
  openSignup1,
  bookingWidget,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Row>
      <Col span={bookingWidget ? 9 : 24} style={{ marginBottom: '10px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Email</p>
            </Col>
            <Col span={24}>
              <Input
                placeholder="annameier@gmail.com"
                allowClear
                bordered={false}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={bookingWidget ? 9 : 24} style={{ marginBottom: '20px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Passwort</p>
            </Col>
            <Col span={24}>
              <Input.Password
                placeholder="********"
                bordered={false}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
        <Row justify="end">
          <Button
            className="authResetPasswordButton"
            onClick={() => openPasswordReset()}
          >
            Passwort zurücksetzen
          </Button>
        </Row>
      </Col>
      <Col span={bookingWidget ? 6 : 24} style={{ marginBottom: '20px' }}>
        <Row justify="end">
          <Col span={bookingWidget ? 12 : 24}>
            <Button
              primary
              className="authLoginButton"
              onClick={() => signIn(email, password)}
            >
              Login
            </Button>
          </Col>
        </Row>
      </Col>
      {!bookingWidget && (
        <Col span={24}>
          <Row justify="center">
            <Button
              className="authRegisterLinkButton"
              onClick={() => openSignup1()}
            >
              Keinen Account? Registrieren!
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
}

export function Signup1({
  signupFields,
  setSignupFields,
  openSignup2,
  openLogin,
  fromInvoice,
  bookingWidget,
}) {
  const [email, setEmail] = useState(signupFields.email);
  const [phone, setPhone] = useState(signupFields.phone);
  const [firstName, setFirstName] = useState(signupFields.firstName);
  const [lastName, setLastName] = useState(signupFields.lastName);
  const [street, setStreet] = useState(signupFields.street);
  const [building, setBuilding] = useState(signupFields.building);
  const [zipcode, setZipcode] = useState(signupFields.zipcode);
  const [city, setCity] = useState(signupFields.city);
  const [recaptcha, setRecaptcha] = useState(signupFields.recaptcha);
  const [conditions, setConditions] = useState(false);
  const [signup1Error, setSignup1Error] = useState(false);

  const validateSignup1 = () => {
    if (
      email
      && email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
      && firstName
      && lastName
      && recaptcha
      && conditions
    ) {
      setSignupFields({
        email,
        phone,
        firstName,
        lastName,
        street,
        building,
        zipcode,
        city,
        recaptcha,
      });
      openSignup2();
    } else {
      setSignup1Error(true);
    }
  };

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Email*</p>
            </Col>
            <Col span={24}>
              <Input
                placeholder="annameier@gmail.com"
                allowClear
                disabled={fromInvoice}
                bordered={false}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Mobilnummer</p>
            </Col>
            <Col span={24}>
              <Input
                placeholder="41 79 298 72 00"
                allowClear
                disabled={fromInvoice}
                bordered={false}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row gutter={10}>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">Vorname*</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="Anna"
                    allowClear
                    bordered={false}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">Nachname*</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="Meier"
                    allowClear
                    bordered={false}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row gutter={10}>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">Strasse</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="Freiestrasse"
                    allowClear
                    bordered={false}
                    onChange={(e) => setStreet(e.target.value)}
                    value={street}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">Nummber</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="62"
                    allowClear
                    bordered={false}
                    onChange={(e) => setBuilding(e.target.value)}
                    value={building}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row gutter={10}>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">PLZ</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="8032"
                    allowClear
                    bordered={false}
                    onChange={(e) => setZipcode(e.target.value)}
                    value={zipcode}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="authFieldBox">
              <Row>
                <Col span={24}>
                  <p className="authFieldLabel">Ort</p>
                </Col>
                <Col span={24}>
                  <Input
                    placeholder="Zürich"
                    allowClear
                    bordered={false}
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    style={{
                      width: '100%',
                      border: 'none',
                      fontFamily: 'Inter',
                      textAlign: 'left',
                      color: '#3A3335',
                      fontSize: '14px',
                      letterSpacing: '-0.1px',
                      lineHeight: '21px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row gutter={10}>
          <Col span={24}>
            <Checkbox onChange={(e) => setConditions(e.target.checked)}>
              Ich akzeptiere die für Sanamia.ch geltenden
              {' '}
              <a
                href="https://softrey.ch/dsg/Softrey-DSE-Sep-2023-DE.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Datenschutzbestimmungen
              </a>
              {' '}
              und bestätige, diese gelesen zu haben.
            </Checkbox>
          </Col>
        </Row>
      </Col>
      <HCaptcha sitekey={env.keys.hCaptchaKey} onVerify={(token) => setRecaptcha(token)} />
      {signup1Error && (
        <Col span={24} style={{ marginBottom: '10px' }}>
          <Row gutter={10}>
            <Col span={24}>
              <p className="errorMessage">Bitte füllen Sie alle Felder aus.</p>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button
          primary
          className="authLoginButton"
          onClick={() => validateSignup1()}
        >
          Registrieren
        </Button>
      </Col>
      {!bookingWidget && (
        <Col span={24}>
          <Row justify="center">
            <Button
              className="authRegisterLinkButton"
              onClick={() => openLogin()}
            >
              Bereits registriert? Einloggen!
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
}

export function Signup2({
  validators,
  updateValidators,
  signupFields,
  signUp,
}) {
  const [passwordSignup, setPasswordSignup] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const isPasswordValid = () => {
    const isValidatorsFalse = some(
      validators,
      (validator) => !validator.isValid,
    );
    const isConfirmTheSame = passwordSignup === passwordConfirmation;

    return !isValidatorsFalse && isConfirmTheSame;
  };

  const validateSignup2 = () => {
    if (passwordSignup === passwordConfirmation && isPasswordValid()) {
      signUp({
        ...signupFields,
        password: passwordSignup,
      });
    }
  };
  // console.log('in AuthSteps', validators);
  return (
    <Row>
      <PasswordFields
        validators={validators}
        passwordSignup={passwordSignup}
        onPasswordSignupChange={(value) => {
          updateValidators(value, passwordSignup, false);
          setPasswordSignup(value);
        }}
        passwordConfirmation={passwordConfirmation}
        onPasswordConfirmationChange={(value) => {
          updateValidators(value, passwordSignup, true);
          setPasswordConfirmation(value);
        }}
      />
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button
          primary
          className="authLoginButton"
          onClick={() => validateSignup2()}
        >
          Registrieren
        </Button>
      </Col>
    </Row>
  );
}

export function Signup3({ activateWithCode }) {
  const [verificationCode, setVerificationCode] = useState('');
  return (
    <Row>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Code</p>
            </Col>
            <Col span={24}>
              <Input
                placeholder="1234"
                bordered={false}
                onChange={(e) => setVerificationCode(e.target.value)}
                value={verificationCode}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        Sie haben einen Sicherheitscode auf Ihre Mobilnummer oder E-Mail Adresse
        erhalten. Bitte geben Sie diesen unten ein.
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button
          primary
          className="authLoginButton"
          onClick={() => activateWithCode(verificationCode)}
        >
          Bestätigen
        </Button>
      </Col>
    </Row>
  );
}

export function Signup4({ close }) {
  return (
    <Row>
      <Col span={24} style={{ marginBottom: '20px' }}>
        Registierung erfolgreich!
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button primary className="authLoginButton" onClick={() => close()}>
          Jetzt loslegen
        </Button>
      </Col>
    </Row>
  );
}

export function PasswordReset({ sendReset, openLogin, openSignup1 }) {
  const [email, setEmail] = useState('');
  return (
    <Row>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Email</p>
            </Col>
            <Col span={24}>
              <Input
                placeholder="annameier@gmail.com"
                allowClear
                bordered={false}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button
          primary
          className="authLoginButton"
          onClick={() => sendReset(email, 'Sanamia')}
        >
          Passwortrücksetzung andfordern
        </Button>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Button
            className="authRegisterLinkButton"
            onClick={() => openLogin()}
          >
            Zurück zum Login
          </Button>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Button
            className="authRegisterLinkButton"
            onClick={() => openSignup1()}
          >
            Keinen Account? Registrieren!
          </Button>
        </Row>
      </Col>
    </Row>
  );
}

export function PasswordRenew({
  validators,
  updateValidators,
  validatePasswordRenew,
}) {
  const [passwordSignup, setPasswordSignup] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  return (
    <Row>
      <PasswordFields
        validators={validators}
        passwordSignup={passwordSignup}
        onPasswordSignupChange={(value) => {
          updateValidators(value, passwordSignup, false);
          setPasswordSignup(value);
        }}
        passwordConfirmation={passwordConfirmation}
        onPasswordConfirmationChange={(value) => {
          updateValidators(value, passwordSignup, true);
          setPasswordConfirmation(value);
        }}
      />
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button
          primary
          className="authLoginButton"
          onClick={() => validatePasswordRenew(passwordSignup, passwordConfirmation)}
        >
          Bestätigen
        </Button>
      </Col>
    </Row>
  );
}

export function PasswordRenewSuccess({ openLogin }) {
  return (
    <Row>
      <Col span={24} style={{ marginBottom: '20px' }}>
        Passwordrücksetzung erfolgreich!
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Button primary className="authLoginButton" onClick={() => openLogin()}>
          Weiter zum Login
        </Button>
      </Col>
    </Row>
  );
}

export function SignUpUnfinished() {
  return <div>TODO</div>;
}
